<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import QcPtl from "./rekap/qc-ptl.vue";
import QcAsesorAc from "./rekap/qc-asesor-ac.vue";
import QcAsesorPq from "./rekap/qc-asesor-pq.vue";
import QcAsesorRolePlayer from "./rekap/qc-asesor-role-player.vue";
import QcPetugasAc from "./rekap/qc-petugas-ac.vue";
import QcPetugasAp from "./rekap/qc-petugas-ap.vue";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Penilaian PEG POG",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    QcPtl,
    QcAsesorAc,
    QcAsesorPq,
    QcAsesorRolePlayer,
    QcPetugasAc,
    QcPetugasAp,
  },
  data() {
    // definisi kolom yang dipakai

    return {
      title: "Rekap QC",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Penilaian PEG POG",
          active: true,
        },
      ],
      text: `
         Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry richardson ex squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis cardigan american apparel, butcher voluptate nisi qui.
        `,
      content: `Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes anderson artisan four loko farm-to-table craft beer twee. Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus.`,
    };
  },
  mounted() {
    // self.getJenisAsesmen();
  },
  methods: {},
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
          
            <b-tabs content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC PTL</span>
                </template>
                <QcPtl />
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC Asesor AC</span>
                </template>
                <QcAsesorAc />
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC Asesor PQ</span>
                </template>
                <QcAsesorPq />
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC Roleplayer</span>
                </template>
                <QcAsesorRolePlayer />
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC Petugas Kelas AC</span>
                </template>
                <QcPetugasAc />
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">QC Petugas Kelas AP</span>
                </template>
                <QcPetugasAp />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
